@import url("https://fonts.googleapis.com/css2?family=Codystar&family=Racing+Sans+One&family=Raleway+Dots&family=Roboto+Slab:wght@500&display=swap");
body,
html {
  background-color: #333;
  width: 100%;
  height: 100%;
}

.videoBackground {
  background-color: gray;
  width: 100%;
  background-image: url("bbg.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  z-index: 1;
}

/* * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
} */

*:focus {
  outline: none;
}

.burgerBars {
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  display: none;
}

.burgerBar {
  height: 0.2rem;
  width: 1.3rem;
  background-color: #baff39;
}

.scrollTop {
  position: fixed;
  bottom: 2%;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  background-color: black;
  border-radius: 50%;
  width: 30px;
  height: 30px;
}

.scrollTop:hover {
  opacity: 1;
}

.scrollBottom {
  position: fixed;
  width: 100%;
  bottom: 5%;
  align-items: center;
  height: 20px;
  justify-content: center;
  z-index: 1000;
  cursor: pointer;
  animation: fadeIn 0.3s;
  transition: opacity 0.4s;
  opacity: 0.5;
  left: 49%;
}

.modalCloseX {
  color: #baff39;
  font-weight: 700;
  background: none;
  border: none;
  position: absolute;
  right: 32.5%;
  top: 2%;
}

.modalCloseX:hover {
  cursor: pointer;
}

.scrollBottom:hover {
  opacity: 1;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 0.5;
  }
}

.card {
  width: 40%;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  transition: all 0.2s ease-in-out;
  transform: scale(3.1);
}

.renderContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  width: 95%;
  height: 100%;
  margin: 0 auto;
  border-right: 4px solid #baff39;
  border-left: 4px solid #baff39;
  border-bottom: 10px solid rgb(160, 144, 144);
  border-bottom-style: inset;
  -webkit-box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
  -moz-box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
  box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(#212121 20%, beige 20%);
  background-position: 0 0;
  background-size: 27px 27px;
}

.searchAndGenre {
  display: flex;
  z-index: 1;
  align-items: flex-end;
  box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(#212121 20%, beige 20%);
  background-position: 0 0;
  background-size: 27px 27px;
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
  padding-top: 20px;
  border-right: 4px solid #baff39;
  border-left: 4px solid #baff39;
  border-top: 4px solid #baff39;
}

.actorSearchAndGenre {
  display: flex;
  z-index: 1;
  align-items: flex-end;
  box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(#212121 20%, beige 20%);
  background-position: 0 0;
  background-size: 27px 27px;
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;

  border-right: 4px solid #baff39;
  border-left: 4px solid #baff39;
  border-top: 4px solid #baff39;
}

.filterContainer {
  background-color: white;
  padding: 10px;
  border: 1px solid #ccc;
  height: 50px;
  margin-right: 40px;
}

.searchAndFilters {
  display: block;
  margin-left: 2.2em;
  width: 25%;
}

.randomContainer {
  display: flex;
  max-height: 90%;
}

.randomCard {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
  max-width: 950px;
  height: 440px;
  padding: 15px;
  margin: 0 auto;
  background-repeat: round;
  margin-bottom: 20px;
  background-image: url(./VHS.jpeg);
}

.modal-content {
}

.randomCard:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  transition: all 0.2s ease-in-out;
  transform: scale(1.1);
}

.movieName {
  font-weight: bold;
  font-size: 1.8em;
  font-family: "Permanent Marker", cursive;
  margin-block-start: 0em;
  margin-block-end: 0em;
}

.infoContainer {
  margin-top: 0px;
  margin-left: 20.5%;
  text-align: center;
  width: 75%;
  max-height: 84%;
  background-color: #d5b78b;
  font-size: 1.2rem;
  padding: 1rem;
  border-radius: 5px;
}

.ratingsContainer {
  display: flex;
  justify-content: space-evenly;
}
.ratingsContainer p {
  margin: 5px;
}

#MPAA {
  border: 1px solid black;
  padding: 2px;
  font-weight: bold;
}

.synopsis {
  font-size: 1.3rem;
  margin-bottom: 1rem;
}

.fa-clock {
  margin-right: 0.3rem;
}

.imageContainer {
  float: left;
}

a {
  color: white;
  text-decoration: none;
}

a:hover {
  transition: 0.2s;
}

.underline:hover {
  text-decoration: underline;
}

.moviePosters {
  height: 42vh;
  width: 15.5vw;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  border-radius: 5px;
}

.moviePosters:hover {
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  transform: scale(1.1);
}

.movieContainer {
  width: 20%;
  text-align: center;
  background-position: 0 0;
  background-size: 27px 27px;
  padding-top: 10px;
  border-bottom: 10px solid rgb(160, 144, 144);
  border-bottom-style: inset;
  box-shadow: 0px 10px 0px rgb(0 0 0 / 10%);
}

.img {
  position: relative;
  width: 15rem;
  max-width: 240px;
  margin: 0 auto;
}

.img-img {
  width: 15rem;
  max-width: 240px;
  display: block;
  border: 2px solid white;
  max-height: 21rem;
}

.img-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
  backdrop-filter: blur(5px);
  border: 1px solid white;
}

.img-overlay > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.img-overlay:hover {
  opacity: 1;
  cursor: pointer;
}

.img-overlay:hover > * {
  transform: translateY(0);
}

.img-title {
  font-size: 2em;
  font-weight: bold;
  color: white;
  font-family: basic-sans, sans-serif;
}

.img-description {
  font-size: 1.25em;
  margin-top: 0.25em;
  color: white;
  font-family: basic-sans, sans-serif;
}

.img-reviewed {
  position: relative;
  width: 150px;
  margin: 0 auto;
}

.img-img-reviewed {
  min-width: 6.9rem;
  max-width: 7rem;
  min-height: 6.9rem;
  max-height: 7rem;
  transform: rotate(-10deg);
  border: 2px solid #05cbe9;
  margin-top: 5px;
  margin-bottom: 5px;
  margin-left: 5px;
}

.img-overlay-reviewed {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 7rem;
  max-height: 7rem;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
  backdrop-filter: blur(5px);
  transform: rotate(-10deg);
  border: 2px solid #05cbe9;
  margin-left: 5px;
  margin-top: 4px;
  text-align: center;
  height: 117px;
}

.img-overlay-reviewed > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.img-overlay-reviewed:hover {
  opacity: 1;
  cursor: pointer;
}

.img-overlay-reviewed:hover > * {
  transform: translateY(0);
}

.img-title-reviewed {
  font-size: 1.2em;
  font-weight: bold;
  color: white;
  font-family: basic-sans, sans-serif;
}

.img-description-reviewed {
  font-size: 1em;
  margin-top: 0.25em;
  color: white;
  font-family: basic-sans, sans-serif;
}

.img-reviewed-random {
  position: relative;
  width: 150px;
  margin: 0 auto;
}

.img-img-reviewed-random {
  min-width: 18rem;
  max-width: 18.1rem;
  min-height: 26rem;
  max-height: 420px;
  border: 2px solid #baff39;
  margin-top: 5px;
  margin-bottom: 5px;
}

.img-overlay-reviewed-random {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 26.2rem;
  max-height: 26.22rem;
  margin-top: 5px;
  min-width: 18rem;
  max-width: 18.1rem;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: opacity 0.25s;
  backdrop-filter: blur(5px);
  border: 2px solid #baff39;
  text-align: center;
}

.img-overlay-reviewed-random > * {
  transform: translateY(20px);
  transition: transform 0.25s;
}

.img-overlay-reviewed-random:hover {
  opacity: 1;
  cursor: pointer;
}

.img-overlay-reviewed-random:hover > * {
  transform: translateY(0);
}

.img-title-reviewed-random {
  font-size: 3.1em;
  font-weight: bold;
  color: white;
  font-family: basic-sans, sans-serif;
}

.img-description-reviewed-random {
  font-size: 1em;
  margin-top: 0.25em;
  color: white;
  font-family: basic-sans, sans-serif;
}

.movieTitleCard {
  background-color: navy;
  height: 40px;
  color: gold;
  border-radius: 25px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.interim {
  display: flex;
}

.genreSignContainer {
  box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(#212121 20%, beige 20%);
  background-position: 0 0;
  background-size: 27px 27px;
  width: 95%;
  padding-top: 20px;
  margin: 0 auto;
  border-left: 4px solid #baff39;
  border-right: 4px solid #baff39;
  border-top: 4px solid #baff39;
  display: flex;
  margin-top: 100px;
  display: flex;
}

.genreSelectContainer {
  background-color: white;
  width: 10%;
}

.genreSign {
  padding-left: 20px;
  padding-right: 20px;
  background-color: gold;
  color: navy;
  text-align: center;
  font-size: 3em;
  line-height: 100px;
  font-family: basic-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  border: 2px solid navy;
  margin-bottom: 5px;
  text-shadow: 1px 1px 1px grey;
  margin-right: 20px;
  width: 400px;
  margin: 0 auto;
}

#demo-row-radio-buttons-group-label {
  color: black;
}

.filters {
  margin-right: 50px;
}

.genreSign.actor {
  width: 400px;
  margin: 0;
  margin-left: 100px;
}

.genreSign.rated {
  width: 600px;
  margin: 0;
  margin-left: 50px;
}

.employeePickContainer {
  box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
  background-image: radial-gradient(#212121 20%, beige 20%);
  background-position: 0 0;
  background-size: 27px 27px;
  width: 95%;
  margin: 0 auto;
  margin-top: 100px;
  border-right: 4px solid #baff39;
  border-left: 4px solid #baff39;
  border-top: 4px solid #baff39;
}

.employeePickWeek {
  background-color: white;
  width: 30%;
  margin: 0 auto;
}

.employeePickWeekText {
  font-size: 20px;
  font-family: basic-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  text-align: center;
}

.movieTitleText {
  text-align: center;
  line-height: 40px;
  height: 30px;
  font-size: 17px;
  font-family: basic-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.hidden {
  display: none;
}

.shown {
  display: block;
}

.hidden :hover {
  display: block;
}

.inputDiv {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.input {
  text-align: center;
  margin-bottom: 2rem;
  margin-top: 1rem;
  width: 345px;
}

.menuSpan {
  margin-right: 1rem;
  vertical-align: middle;
}

.navContainer {
  height: 2rem;
  background-color: #2e2e2e;
  padding-bottom: 0.8rem;
  padding-top: 0.8rem;
  display: flex;
  column-gap: 50px;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}

.navItems {
  font-family: "Dongle", sans-serif;
  font-size: 1.5rem;
  color: white;
}

.navItems::after {
  content: "";
  height: 2px;
  width: 0%;
  background-color: #baff39;
  display: block;
  transition: 0.3s ease-in-out;
  cursor: pointer;
}

.navItems:hover::after {
  content: "";
  height: 3px;
  width: 100%;
  background-color: #baff39;
  display: block;
}

.navItems:hover {
  cursor: pointer;
}

.page-container {
  min-height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
}

#contactNav {
  margin-right: 50px;
}

.outOf {
  font-size: 12px;
  color: #a19c9c;
}

.outOfLarge {
  font-size: 18px;
  color: #a19c9c;
}

#footerWrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.footer-visible {
  font-weight: 700;
  font-family: basic-sans, sans-serif;
  font-style: normal;
  color: white;
  text-align: center;
  padding-top: 0.8rem;
  padding-bottom: 1.8rem;
  background-color: #2e2e2e;
}

main {
  flex-grow: 1;
}

.writing-image {
  border: 3px solid #baff39;
}

.writing-section {
  padding: 6rem 0;
}

.writing-picture {
  width: 500px;
}

.writing-item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 5rem;
  padding-left: 1rem;
}

.writing-item:last-child {
  margin-bottom: 0;
}

.writing-item:hover {
  text-decoration: none;
  color: white;
  background-color: #2e2e2e;
  transition: 0.4s;
}

.writingJunkDrawerheader {
  text-align: center;
  font-family: basic-sans, sans-serif;
  margin-top: 5%;
  color: white;
}

.writing-img {
  flex: 1;
}

.writing-description {
  flex: 1;
}

.has-margin-right {
  margin-right: 5rem;
}

.writing-description h1 {
  font-size: 3rem;
  font-weight: 500;
}

.writing-description p {
  line-height: 40px;
  font-size: 18px;
}

.writing-description h6 {
  font-size: 1.5rem;
  text-transform: uppercase;
  font-weight: 300;

  color: #baff39;
}

.writing-outer-container {
}

.writing-container {
  width: 90%;
  max-width: 120rem;
  height: 100%;
  margin: 0 auto;
  position: relative;
  font-family: basic-sans, sans-serif;
  font-style: normal;
  color: white;
}

.icon-link {
  margin-right: 20px;
  margin-left: 20px;
}

/* ///////// Modal Stuff ///////// */

.modal {
  width: 30rem;
  max-width: 1440px;
  height: 46rem;
  max-height: 796px;
  background: linear-gradient(70deg, #000511 0%, #213763 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 1%;
  padding: 1em;
  border: 2.5px solid #baff39;
  border-radius: 5px;
}

.modal-img {
  min-width: 0;
  min-height: 0;
}

.tagline {
  color: gold;
  font-size: 1.3rem;
  margin: 0;
  font-family: basic-sans, sans-serif;
  text-align: center;
  margin-top: -10px;
}

.tagline-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.tagline-writer {
  text-align: center;
  color: gold;
  margin-bottom: 10px;
  font-size: 14px;
  font-family: basic-sans, sans-serif;
  text-align: center;
  margin: 0 auto;
}

.topPicsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

.picture1 {
  width: 18rem;
  max-width: 18rem;
  height: 11.5rem;
  max-height: 11.5rem;
  border: 2px solid #05cbe9;
}

.picture2 {
  width: 11rem;
  max-width: 11rem;
  height: 11.5rem;
  max-height: 11.5rem;
  border: 2px solid #05cbe9;
}

.picture3 {
  width: 95%;
  transform: rotate(-10deg);
  margin-left: 6px;
  margin-top: 8px;
  border: 2px solid #05cbe9;
}

.modal-synopsis {
  font-size: 1.1em;
  min-height: 13%;
  max-height: 15%;
  padding-top: 1rem;
  padding-bottom: 0.5rem;
  overflow: hidden;
}

.bonusFeatures {
  width: 100%;
  min-height: 9rem;
  max-height: 10rem;
  padding: 1.5%;
  padding-bottom: 1%;
  background-color: gray;
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  display: flex;
  margin-left: -15px;
  border-left: none;
}

.pictureThreeContainer {
  height: 100%;
  width: 150px;
  position: relative;
}

.pictureThreeContainer:hover .transparentOverlay {
  opacity: 1;
  cursor: pointer;
}

.transparentOverlay {
  height: 100%;
  width: 100%;
  height: 75px;
  width: 80px;
  transform: rotate(-10deg);
  margin-left: 13px;
  margin-top: 8px;
  opacity: 0;
  transition: 0.5s ease;
  position: absolute;
}

.transparentOverlayText {
  color: white;
  font-size: 20px;
  position: absolute;
  bottom: 60%;
  left: 90%;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-weight: bold;
  text-shadow: 0 0 4px red;
}

/* body {
  margin: 0;
} */

header {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  transform-style: preserve-3d;
  z-index: -1;
}

.storeClosing {
  width: 100%;
  height: 100%;
}

.randomBullets {
  text-align: center;
  margin-left: 0.5rem;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}

.comingSoon {
  transform: skewY(-18deg);
  padding: 5px;
  background-color: #baff39;
  color: black;
  margin-top: 5rem;
  font-weight: bold;
  width: 98%;
}

.content {
  max-width: 50em;
  margin: 0 auto;
  position: relative;
}

.background {
  transform: translateZ(-10px) scale(1.5);
  object-fit: contain !important;
  margin-left: 7%;
  background-color: rgba(0, 0, 0, 0.01);
}

.foreground {
  /* transform: translateZ(-5px) scale(1); */
  display: none;
}

.background,
.foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.title {
  font-size: 7rem;
  color: white;
  text-shadow: 0 0 5px black;
  margin-top: 85vh;
  font-family: basic-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
  text-align: center;
}

.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 40px;
  height: 100vh;
  grid-gap: 20px;
}
.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  border: 2px solid #baff39;
  font-size: 30px;
  text-align: center;
  min-width: minmax(0, 1fr);
  background-size: cover;
  position: relative;
  min-height: 230px;
  max-height: 230px;
}

.grid-item:hover {
  cursor: pointer;
  opacity: 0.7;
  transition: 0.4s;
}

.grid-item.one {
  background-image: url("./topfive-pictures/2far2P.png");
}

.grid-item.two {
  background-image: url("./pictures/topfive/sequelwins.jpeg");
}

.grid-item.three {
  background-image: url("./pictures/topfive/zingers.jpeg");
}
.grid-item.four {
  background-image: url("./topfive-pictures/koogler.jpeg");
}

.grid-item.five {
  background-image: url("./pictures/topfive/taglines.jpeg");
}

.grid-item.six {
  background-image: url("./pictures/topfive/badvillains.jpeg");
}

.grid-item.seven {
  background-image: url("./pictures/topfive/wannabe.jpeg");
}

.grid-item.eight {
  background-image: url("./pictures/topfive/90s.jpeg");
}

.grid-item.nine {
  background-image: url("./pictures/topfive/80s.jpeg");
}

.grid-item.ten {
  background-image: url("./pictures/topfive/stupidtrope.jpeg");
}

.grid-item.eleven {
  background-image: url("./pictures/topfive/halloween.jpeg");
}

.grid-item.twelve {
  background-image: url("./pictures/topfive/thanksgiving.jpeg");
}

.grid-item.thirteen {
  background-image: url("./pictures/topfive/christmas.jpeg");
}

.grid-item.fourteen {
  background-image: url("./pictures/topfive/dirtbags.jpeg");
}

.grid-item.fifteen {
  background-image: url("../src/grabbag-pictures/odethumbnail-black.jpeg");
}

.grid-item.sixteen {
  background-image: url("../src/coldtake-pictures/community.jpeg");
}

.grid-item.seventeen {
  background-image: url("../src/coldtake-pictures/johnnyutah.jpeg");
}

.grid-item.eighteen {
  background-image: url("../src/coldtake-pictures/arabia.jpeg");
}

.grid-item.nineteen {
  background-image: url("../src/coldtake-pictures/godfatherstinks.jpeg");
}

.grid-item.twenty {
  background-image: url("../src/grabbag-pictures/drama.jpeg");
}

.grid-item.twentyone {
  background-image: url("../src/coldtake-pictures/thrones.webp");
}

.grid-item.twentytwo {
  background-image: url("../src/coldtake-pictures/nightschool.jpeg");
}

.grid-item.twentythree {
  background-image: url("../src/coldtake-pictures/jumanji.jpeg");
}

.grid-item.twentyfour {
  background-image: url("../src/coldtake-pictures/pacino.jpeg");
}

.grid-item.twentyfive {
  background-image: url("../src/grabbag-pictures/hackerman-tn.jpg");
}

.grid-item.twentysix {
  background-image: url("../src/grabbag-pictures/BC_TN2.jpg");
}

.grid-item.twentyseven {
  background-color: gray;
}

.grid-item.twentyeight {
  background-image: url("../src/grabbag-pictures/football/FS-Football.jpg");
}

.grid-item.twentynine {
  background-image: url("../src/coldtake-pictures/Morph-Pimp-1.jpg");
}

.grid-item.thirty {
  background-image: url("../src/grabbag-pictures/substitute.jpeg");
}

.grid-item.thirtyone {
  background-image: url("../src/grabbag-pictures/spoof-nielsen.webp");
}

.articlesBackground {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  font-family: basic-sans, sans-serif;
  position: absolute;
  bottom: 0;
  text-align: center;
  width: 100%;
}

.article-container {
  width: 55%;
  margin: 0 auto;
  margin-bottom: 100px;
  font-family: basic-sans, sans-serif;
  color: white;
  height: 100%;
}

.movieGameTitle {
  font-size: 80px;
}

.headerBackground {
  background: rgba(0, 0, 0, 0.5);
}

.article-container-header {
  width: 100%;
  margin: 0 auto;
  font-family: "Bungee", cursive;
}

.article-container-header p {
  padding: 1rem 0;
  font-size: 2.5rem;
  font-weight: 600;
}

.article-container-body p {
  font-size: 18px;
  margin-top: 10px;
  line-height: 30px;
}

.articleContentContainer {
  display: flex;
}

.relatedSidebar {
  width: 20%;
  padding: 0 5%;
  margin-bottom: 3%;
  font-family: basic-sans, sans-serif;
  height: 30%;
  position: -webkit-sticky;
  position: sticky;
  top: 9.5%;
}

.movieNameDrinks {
  margin: 0 auto;
}

.aboutWriterContainer {
  width: 85%;
  margin: 0 auto;
  padding: 0 5%;
  margin-bottom: 100px;
  font-family: basic-sans, sans-serif;
  color: white;
  height: 100%;
  /* background-color: blue; */
  margin-top: 5%;
  display: flex;
}

.writerThumbnail {
  height: 220px;
  width: 220px;
  border-radius: 50%;
  background-color: darkblue;
  border: 3px solid #baff39;
}

.writerThumbnailImage {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.writerName {
  font-size: 3rem;
  font-family: "Bungee", cursive;
}

.nameAndBlurbContainer {
  margin-left: 2rem;
  width: 50%;
}

.writerBlurb {
  font-size: 1.25rem;
  margin-top: 1rem;
}

.alsoWrittenByContainer {
  width: 300px;
  width: 85%;
  padding: 0 5%;
}

#mc_embed_signup {
  background-color: #6e6e6e;
  padding: 30px;
  text-align: center;
  width: 500px;
}

.contactFormDiv {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

div.response {
  background-color: blue;
}

.seeAlso {
  width: 100%;
}

.seeAlsoHeader {
  font-size: 1.4rem;
  font-family: "Bungee", cursive;
  color: white;
  padding-bottom: 0.7rem;
  width: 60%;
}

.movieNameDrinksText {
  font-size: 20px;
  font-weight: bold;
  font-family: basic-sans, sans-serif;
  color: gold;
  text-shadow: 2px 2px darkblue;
}

.otherArticles {
  margin-top: 1.6rem;
  border-top: 1px solid #baff39;
  width: 70%;
  padding: 20px;
}

.otherArticles:hover {
  transition: 0.5s;
  background-color: #2e2e2e;
  cursor: pointer;
}

.otherArticlesThumbnail {
  height: 220px;
  min-width: 339px;
  max-width: 340px;
}

.thumbnailTitleContainer {
  display: flex;
}

.otherArticleTitle {
  font-size: 30px;
  margin-left: 0.5rem;
  font-weight: 700;
  font-family: basic-sans, sans-serif;
  color: white;
}

.readMore {
  color: #baff39;
  font-family: basic-sans, sans-serif;
  margin-left: 0.5rem;
  font-size: 1rem;
  margin-top: 1rem;
}

.otherTitleSynopsisContainer {
  width: 100%;
  margin-left: 10px;
}

.otherArticleSynopsis {
  margin-left: 0.5rem;
  font-family: basic-sans, sans-serif;
  color: white;
  font-size: 1rem;
  line-height: 1.9rem;
  max-height: 125px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: 20px;
}

.currentRoute {
  color: white;
  font-size: 12px;
  padding: 0 0 0 7.5%;
  margin-top: 6%;
}

.published {
  font-size: 0.8rem;
}

.authorName {
  font-size: 0.8rem;
  display: flex;
}

#author-name {
  margin-left: 0.2rem;
}

.top-social-icons {
  display: flex;
  margin-left: 74%;
  margin-top: -2%;
}

.top-social-icon {
  border-radius: 50%;
  height: 2rem;
  line-height: 2.2rem;
  text-align: center;
  font-size: 1rem;
  width: 2rem;
  margin-right: 0.5rem;
}

#circle-facebook {
  background-color: #3b5998;
}

#circle-twitter {
  background-color: #04abed;
}

#circle-reddit {
  background-color: #eb5528;
}

#circle-envelope {
  background-color: gray;
}

#circle-facebook:hover {
  opacity: 0.85;
}

#circle-twitter:hover {
  opacity: 0.85;
}

#circle-reddit:hover {
  opacity: 0.85;
}

#circle-envelope:hover {
  opacity: 0.85;
}

.readMoreContainer {
  margin-top: 2rem;
  background-color: #4d4d4da3;
  padding: 2rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
}

.readMoreContainer:hover {
  background-color: #24262a;
  transition: 0.5s;
  cursor: pointer;
}

.readMoreHeader {
  text-align: center;
  font-family: "Bungee", cursive;
  font-size: 1.3rem;
  color: #baff39;
  padding-bottom: 1rem;
}

.readMoreContent {
  font-family: basic-sans, sans-serif;
  font-size: 1.5rem;
  font-weight: 600;
  text-align: center;
  padding-bottom: 1.5rem;
}

.underscore {
  border: 3px solid #baff39;
  background-color: #baff39;
  width: 10%;
  margin: 0 auto;
}

.social-media {
  margin-top: 1rem;
  display: flex;
  flex-shrink: 0;
  padding-bottom: 10px;
}

.social-media-bottom {
  margin-top: 1rem;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-bottom: 10px;
}

.social-button {
  min-height: 0.7rem;
  min-width: 6.5rem;
  background-color: red;
  padding: 1rem;
  margin-right: 0.5rem;
  border-radius: 5px;
  border: none;
  font-family: basic-sans, sans-serif;
  font-size: 1rem;
}

#social-facebook {
  background-color: #3b5998;
  color: white;
  font-weight: 600;
  width: 2rem;
}

#facebook-square {
  margin-right: 0.4rem;
}

#social-twitter {
  background-color: #04abed;
  color: white;
  font-weight: 600;
  width: 2rem;
}

#twitter-square {
  margin-right: 0.4rem;
}

#social-reddit {
  background-color: #ff5700;
  color: white;
  font-weight: 600;
  width: 2rem;
}

#reddit-square {
  margin-right: 0.4rem;
}

#social-envelope {
  background-color: gray;
  color: white;
  font-weight: 600;
  width: 2rem;
}

#envelope-square {
  margin-right: 0.4rem;
}

#social-facebook:hover {
  opacity: 0.85;
  cursor: pointer;
}

#social-twitter:hover {
  opacity: 0.85;
  cursor: pointer;
}

#social-reddit:hover {
  opacity: 0.85;
  cursor: pointer;
}

#social-envelope:hover {
  opacity: 0.85;
  cursor: pointer;
}

#sidebar-image-caption {
  font-size: 1rem;
  font-family: "Bungee", cursive;
  color: white;
}

.seeAlso {
  font-size: 1.4rem;
  font-family: "Bungee", cursive;
  color: white;
  border-bottom: 2px solid #baff39;
  padding-bottom: 0.7rem;
}

.latestContainer {
  padding: 30px 0px 0px 40px;
  width: 6rem;
}

.latest {
  font-size: 1.5rem;
  font-family: "Bungee", cursive;
  color: white;
  border-bottom: 4px solid #baff39;
  padding-bottom: 0.7rem;
}

.notRatedTitle {
  min-height: 10%;
  max-height: 11%;
  font-family: basic-sans, sans-serif;
  font-size: 40px;
  font-weight: 600;
  text-align: center;
  max-height: 50px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-shadow: 2px 2px #ff0000;
  margin-bottom: 1rem;
}

.notRatedPoster {
  height: 300px;
  border: 2px solid #baff39;
  margin: 0 auto;
}

.notRatedPoster:hover {
  cursor: pointer;
}

.modal-notRated {
  width: 32rem;
  max-width: 560px;
  height: 29rem;
  max-height: 550px;
  background: linear-gradient(70deg, #000511 0%, #213763 100%);
  color: #fff;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  margin-top: 10%;
  padding: 1em;
  border: 2.5px solid #baff39 !important;
  border-radius: 5px;
}

.modal-filmInfo-notRated {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: center;
  font-weight: bold;
  font-size: 20px;
  padding: 5px;
}

.notRatedPosterContainer {
  display: flex;
  position: relative;
}

.article-container-body h2 {
  margin-top: 10px;
  font-size: 2rem;
}

.article-image {
  width: 100%;
  max-height: 20rem;
  padding: 20px 0px 0px 0px;
  object-fit: cover;
}

.article-image-sidebar {
  width: 100%;
  min-height: 9.9rem;
  max-height: 10rem;
  padding: 20px 0px 0px 0px;
  object-fit: cover;
}

.article-image-vertical-div {
  display: grid;
  place-items: center;
  object-fit: contain;
  margin-top: 10px;
}

.article-image-vertical {
  min-height: 31rem;
  max-height: 32rem;
  max-width: 22rem;
}

#jackFrost:hover {
  cursor: pointer;
}

#image-caption {
  text-align: center;
  padding: 0px;
  font-size: 14px;
  margin-top: 0px;
}

section {
  font-size: 2rem;
  padding: 2rem;
  background-color: #333;
  color: white;
}

.games-wrapper {
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  perspective: 20px;
}

.chevron::before {
  border-style: solid;
  border-width: 0.25em 0.25em 0 0;
  content: "";
  display: inline-block;
  height: 0.45em;
  left: 0.15em;
  position: relative;
  top: 0.15em;
  transform: rotate(-45deg);
  vertical-align: top;
  width: 0.45em;
}

.chevron.left:before {
  left: -2em;
  transform: rotate(-135deg);
  margin-top: 1.5rem;
}

.chevron.right:before {
  left: 2em;
  transform: rotate(45deg);
  margin-top: 1.5rem;
}

.chevron.left.desktop:before {
  left: -2em;
  transform: rotate(-135deg);
  margin-top: 1.5rem;
}

.chevron.right.desktop:before {
  left: 2em;
  transform: rotate(45deg);
  margin-top: 1.5rem;
}

.chevron:hover {
  cursor: pointer;
  color: #baff39;
}

.mobileChevronsArticlesPage {
  display: none;
}

.games-background {
  transform: translateZ(-10px) scale(1.5);
}

.games-foreground {
  transform: translateZ(-5px) scale(1);
}

.games-background,
.games-foreground {
  position: absolute;
  height: 100%;
  width: 100%;
  object-fit: cover;
  z-index: -1;
}

.games-title {
  font-size: 10rem;
  color: white;
  text-shadow: 0 0 5px black;
  font-family: basic-sans, sans-serif;
  font-weight: 700;
}

.movieGameTextContainer {
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  font-family: basic-sans, sans-serif;
  font-weight: 400;
  font-size: 26px;
}

.aboutTextContainer {
  text-align: center;
  width: 70%;
  margin-left: auto;
  margin-right: auto;
  margin-top: 100px;
  font-family: basic-sans, sans-serif;
  font-weight: 400;
  font-size: 26px;
}

.aboutTextContainer p {
  font-size: 20px;
}

.green {
  color: #baff39;
  font-weight: 700;
}

#movieGameTitle {
  font-size: 80px !important;
}

.movieGameContainer-left {
  display: flex;
  align-items: center;
  background-color: #05cbe9;
  padding: 0.5em;
  border-top-right-radius: 0.5em;
  border-bottom-right-radius: 0.5em;
  border-left: 4px solid #0c77f8;
  color: #0734d9;
}

.movieGameContainer-right {
  display: flex;
  align-items: center;
  justify-content: right;
  border-right: 4px solid #0ba40d;
  background-color: #baff39;
  border-top-left-radius: 0.5em;
  border-bottom-left-radius: 0.5em;
  padding: 0.5em;
  color: #075b09;
}

.movieGameContainer-right p {
  margin-right: 15px;
}

.movieGameAvatar {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  margin-right: 0.5em;
}

.bonusFeatureBulletContainer {
  background-color: gold;
  border-radius: 50px;
  color: blue;
  text-align: center;
  font-weight: bold;
  text-shadow: 1px 1px 1px gray;
  padding: 0.5%;
  width: 80%;
  margin-top: -2.5%;
  letter-spacing: 5px;
}

.cast {
  text-align: center;
  width: 95%;
  font-size: 13px;
  margin-top: 8%;
  margin-bottom: 5%;
  margin-left: 2%;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
  max-height: 3rem;
  position: relative;
}

.writingPageDescription {
  padding-top: 4rem;
  font-size: 2rem;
  background-color: #242424;
  text-align: center;
  height: 12rem;
  color: white;
  font-family: basic-sans, sans-serif;
  justify-content: center;
  align-items: center;
}
.writingPageDescription h2 {
  padding-top: 4rem;
  text-transform: uppercase;
  font-size: 2.2rem;
}

.writingPageSubheader {
  font-size: 1rem;
}

.writingPageDescription .p {
}

.bonusFeatureBullets {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
  font-weight: 500;
  color: black;
  width: 100%;
  margin-left: 1rem;
}

ul {
  padding: 0px;
  margin: 7px;
  margin-right: 10%;
}

.bottomBullet {
  margin-top: 5px;
}

.seeSimilar {
  margin-top: 5px;
  text-decoration: underline;
}

.seeSimilar:hover {
  color: #033969;
}

li.seeSimilar::marker {
  color: black;
}

li.seeSimilarPurple::marker {
  color: black;
}

.seeSimilarPurple {
  color: #033969;
  margin-top: 5px;
}

.seeSimilarPurple:hover {
  cursor: pointer;
}

.seeSimilar:hover {
  cursor: pointer;
}

.letsGo {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.pairsWith {
  border: 2px solid #c5c1c1;
  width: 100%;
  background-color: gray;
  border-radius: 10px;
  display: flex;
}

.pairsWith:hover .recommendedDrink {
  /* opacity: 0.8;
  cursor: pointer; */
  color: gold;
  cursor: pointer;
  text-decoration: underline;
}

.recipeContainer {
  width: 29rem;
  max-width: 29rem;
  margin-left: -0.3%;
  background-color: white;
  position: absolute;
  margin-top: 0.7%;
  background-color: gold;
  height: 3rem;
  max-height: 4rem;
  bottom: -5%;
  border-radius: 10px;
  animation: fadein 1s;
}

.pairsWithTextDiv {
  vertical-align: middle;
  margin-bottom: 5px;
  margin-top: 5px;
  padding: 5px;
  font-size: 14px;
  color: black;
  font-weight: 550;
  max-height: 1.7rem;
  overflow-y: scroll;
}

.pairsWithTextDiv::-webkit-scrollbar {
  width: 0.5em;
}

.pairsWithTextDiv::-webkit-scrollbar-track {
  background-color: transparent;
}

.pairsWithTextDiv::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.modal-body {
  font-size: 1rem;
  line-height: 1.6;
  border: 1px solid red;
}

.modal-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 3em;
  border: 1px solid red;
}

.modal-btn {
  font-size: 1.25rem;
  background: #05cbe9;
  color: #111d4a;
  text-decoration: none;
  font-weight: 700;
  padding: 0.35em 1em;
  letter-spacing: 1px;
  border: 1px solid red;
}

.newSpace {
  text-align: center;
  font-size: 70px;
  font-family: basic-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
  background-color: #6e6e6e;
  padding-bottom: 150px;
  padding-top: 100px;
}

.newSpaceSpan {
  color: #baff39;
}

.newSpaceText {
  font-family: basic-sans, sans-serif;
  font-weight: 300;
  text-align: center;
  background-color: #6e6e6e;
  font-size: 28px;
  margin-top: 40px;
}

.celebTestimonials {
  background-color: #6e6e6e;
}

.buzzing {
  font-family: basic-sans, sans-serif;
  font-weight: 900;
  text-align: center;
  margin-top: 100px;
  font-size: 40px;
}

.dolph {
  border-radius: 50%;
  min-height: 149px;
  max-height: 150px;
  min-width: 149px;
  max-width: 150px;
  margin-bottom: 80px;
  margin-top: -80px;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
}

.drDolphContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.drDolph {
  font-size: 16px;
  letter-spacing: 3px;
  margin-top: -70px;
}

.grayBackground {
  background-color: #383838;
  height: 400px;
  width: 1000px;
  margin: 0 auto;
  margin-top: 200px;
  border-bottom: 2px solid #baff39;
}

.grayBackgroundInner {
  height: 200px;
  width: 715px;
  margin: 0 auto;
  color: white;
  font-family: basic-sans, sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 30px;
  display: flex;
}

.genreSign.employee {
  width: 30rem;
}

.randomSpecialFeatures {
  margin-bottom: 0.5rem;
}

.newReviewDate {
  font-size: 1rem;
  margin-top: 1rem;
}

.reasonContainer {
  background-color: #6e6e6e;
  padding-bottom: 100px;
}

.reasonNumber {
  font-size: 120px;
  font-family: acier-bat-outline, sans-serif;
  font-weight: 400;
  font-style: normal;
  color: #baff39;
}

.reasonText {
  color: white;
  font-size: 25px;
  font-family: basic-sans, sans-serif;
  font-weight: 400;
  margin-left: 20px;
  padding-top: 20px;
}

.reasonText-1 {
  color: white;
  font-size: 25px;
  font-family: basic-sans, sans-serif;
  font-weight: 400;
  margin-left: 20px;
  padding-top: 20px;
  margin-left: 43px;
}

.numberContainer {
  display: flex;
  padding: 10px;
  width: 50%;
  margin-left: 30%;
  vertical-align: top;
}

.missingReason {
  font-family: basic-sans, sans-serif;
  font-weight: 600;
  text-align: center;
  background-color: #6e6e6e;
  font-size: 40px;
  margin-top: 100px;
}

.reviewsReimagined {
  text-align: center;
  font-size: 80px;
  font-family: basic-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
  padding-bottom: 150px;
}

.reviewsReimaginedHeader {
  margin-top: 100px;
}

.reviewsReimaginedText {
  font-family: basic-sans, sans-serif;
  font-weight: 300;
  text-align: center;
  font-size: 28px;
  margin-top: 40px;
}

.bottomContainerContainer {
  min-height: 5rem;
}

.bottomContainer {
  width: 97%;
  display: flex;
  padding: 0px 10px 0px 10px;
  font-size: 13px;
  text-align: center;
  margin-left: -0.45%;
  display: flex;
  max-height: 5.5rem;
}

.modal-filmInfo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: bold;
  padding: 5px;
  font-size: 20px;
  margin-top: 1rem;
}

.genreSelectDropdown {
  margin-top: 100px;
}

.randomButton {
  height: 50px;
  width: 200px;
  font-family: basic-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  background-color: #baff39;
  color: black;
  border-radius: 5px;
  border: none;
  transition: 0.5s;
}

.beerButton {
  height: 50px;
  width: 200px;
  font-family: basic-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  background-color: #baff39;
  color: black;
  border-radius: 5px;
  border: none;
  transition: 0.5s;
  margin-right: 1em;
}

.shopButton {
  height: 50px;
  width: 200px;
  font-family: basic-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 20px;
  background-color: #00c853;
  color: black;
  border-radius: 5px;
  border: none;
  transition: 0.5s;
  margin-right: 1em;
}

.beerButton:hover {
  background-color: #fbb117;
  cursor: pointer;
}

.shopButton:hover {
  background-color: #fbb117;
  cursor: pointer;
}

.test-chimp {
  height: 500px;
  width: 600px;
  background-color: white;
}

.randomButton:hover {
  background-color: #fbb117;
  cursor: pointer;
}

.randomButtonDiv {
  width: 100%;
  display: flex;
  justify-content: space-around;
}

.randomizerText {
  font-family: basic-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 3rem;
  color: white;
  text-align: center;
  margin-top: 3rem;
  padding-top: 2rem;
}

.randomizerSubtext {
  font-family: basic-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 2rem;
  color: white;
  text-align: center;
  margin-bottom: 2rem;
}

.randomizerLimit {
  font-family: basic-sans, sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 1.6rem;
  color: white;
  text-align: center;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

.iconOuterContainer {
  border: 2px solid #c5c1c1;
}

.iconContainer {
  padding-left: 1rem;
  padding-top: 0.5rem;
}

.iconContainer2 {
  padding-right: 1rem;
  padding-top: 0.5rem;
}

.iconContainer i {
  height: 30px;
}

.modal-mpaa-rating {
  border: 1px solid white;
  padding: 5px;
}

.article-link {
  text-decoration: underline;
}

.vhsMPAA {
  height: 30px;
  width: 30px;
  margin-right: 5px;
}

.endOfGenre {
  height: 40px;
  background-color: white;
  color: black;
  text-align: center;
  padding: 20px;
  font-size: 20px;
  margin-bottom: 50px;
  font-weight: bold;
}

.contactLogoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactLogoContainer img {
  max-width: 30%;
  max-height: 30%;
}

.contactText {
  font-size: 5rem;
  color: white;
  margin-top: 4rem;
  font-family: basic-sans, sans-serif;
  text-align: center;
}

.form-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 40%;
  padding: 30px;
  background-color: #6e6e6e;
  border-radius: 5px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0 auto;
}

.email-header {
  text-align: center;
  margin-bottom: 1rem;
  font-family: basic-sans, sans-serif;
  color: #baff39;
}

#email-form {
  width: 100%;
}

.email-text {
  text-align: center;
  margin-bottom: 2rem;
  font-family: basic-sans, sans-serif;
  color: white;
  font-size: 1.2em;
}

.movieBackground {
  height: 100vh;
  background-color: gray;
  width: 100%;
  background-image: url("bbg.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  z-index: 1;
}

.movieBackground::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

.movieBackgroundRandom {
  position: relative;
  height: 100vh;
  background-color: gray;
  width: 100%;
  background-image: url("bbg.jpeg");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  z-index: 1;
}

.movieBackgroundRandom::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: -1;
}

form {
  display: flex;
  flex-direction: column;
}

input {
  padding: 10px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 3px;
}

.contactPageEmail {
  text-align: center;
  font-family: basic-sans, sans-serif;
  font-size: 2rem;
  color: white;
  font-weight: 700;
}

.youtube-container {
  margin-top: 1rem;
  margin-bottom: 2rem;
}

.merchRowOneContainer {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10%;
}

.merchRowOne {
  height: 350px;
  border: 3px solid #baff39;
}

.merchRowOne:hover {
  border: 3px solid #00704b;
  cursor: pointer;
}

.merchCategories {
  height: 350px;
  border: 2px solid black;
  width: 20%;
  background-color: #9a1e23;
}

.merchCategories2 {
  height: 350px;
  width: 20%;
}

.merchTypes {
  margin-left: 10px;
  color: white;
  font-weight: bold;
  padding: 10px;
}

.merchTypes:hover {
  cursor: pointer;
  background-color: #052943;
  color: white;
}

.merchDescription {
  color: rgb(56, 238, 238);
}

.merchPrice {
  font-weight: bold;
  margin-top: -10px;
}

.merchButton {
  background-color: black;
  color: #baff39;
  border: 1px solid white;
  padding: 10px;
  padding-right: 20px;
  padding-left: 20px;
  text-align: center;
  font-family: "Montserrat", sans-serif;
  font-size: 15px;
  font-weight: bold;
}

.merchButton:hover {
  cursor: pointer;
  background-color: #baff39;
  color: black;
  border: 1px solid black;
}

#film {
  color: white !important;
  font-size: 2rem;
  font-family: "Bungee", cursive;
  margin-left: 20px;
  text-decoration: none;
  color: inherit;
  z-index: 20;
}

#slobs {
  font-size: 2rem;
  font-family: "Creepster", cursive;
  color: #baff39;
  z-index: 20;
}

.relatedTitles {
  text-align: center;
  margin-bottom: 5px;
  font-size: 2em;
  font-weight: bold;
  color: blue;
  font-family: "Codystar", cursive;
  font-weight: 700;
  text-shadow: 1px 1px 1px grey;
}

.relatedFlex {
  display: flex;
  justify-content: space-around;
}

.relatedFlex img {
  border: 2px solid blue;
}

.relatedX {
  color: blue;
  text-align: right;
  margin-right: 5px;
  font-weight: bold;
  font-family: basic-sans, sans-serif;
}

.relatedX:hover {
  cursor: pointer;
}

.relatedContainer {
  height: 18rem;
  width: 29.8rem;
  max-width: 29.8rem;
  background-color: #ffd700;
  position: absolute;
  animation: fadein 1s;
  border: 2px solid blue;
  display: block;
}

.invisibleRelatedContainer {
  display: none;
}

.relatedContainer img {
  height: 13rem;
  max-height: 13rem;
  width: 30%;
  max-width: 30%;
}

.recipeIngredients {
  font-size: 1em;
  margin-top: 2%;
  text-align: center;
  font-weight: bold;
  color: blue;
  font-family: "Codystar", cursive;
  font-weight: 700;
  text-shadow: 1px 1px 1px grey;
  padding: 1%;
}

#outlined-basic {
  height: 35px;
  background-color: white;
  border-radius: 0px;
}

.emptyDVD {
  text-align: center;
}

.noActor {
  text-align: center;
}

.noActorPicture {
  max-height: 25rem;
}

.noActorText {
  font-size: 2.9em;
  background-color: white;
  padding: 0.5em;
  font-family: basic-sans, sans-serif;
  font-weight: 600;
  font-style: normal;
  border-radius: 50px;
  border: 1px dotted blue;
}

.dvdPicture {
  width: 42rem;
  margin-top: 15px;
}

.noteDvd {
  height: 200px;
  width: 300px;
  background-color: white;
  position: absolute;
  top: 40%;
  right: 50%;
}

.dropdown {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  min-width: 160px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 0px;
  background-color: #2e2e2e;
}

.supportTitle {
  font-size: 50px;
  margin-top: 4rem;
}

.dropdown-content a {
  color: white;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-family: basic-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  border-bottom: 1px solid rgb(61, 61, 61);
}

.dropdown-content a:hover {
  background-color: #252424;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.dropdown:hover .dropbtn {
  background-color: #3e8e41;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

.FAQ {
  height: 100vh;
  background-color: #333;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: basic-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
  margin-bottom: 100px;
}

.faqLink {
  color: #05cbe9;
}

.faqLink:hover {
  color: #019ab2;
}

.container {
  width: 100%;
  max-width: 80rem;
  margin: 0 auto;
  padding: 0 1.5rem;
}

.accordion-item {
  background-color: #6e6e6e;
  border-radius: 0.4rem;
  margin-bottom: 1rem;
  padding: 1rem;
  box-shadow: 0.5rem 2px 0.5rem rgba(0, 0, 0, 0.1);
}

.accordion-link {
  font-size: 1.6rem;
  color: rgba(255, 255, 255, 0.8);
  background-color: #6e6e6e;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 0;
  text-decoration: none;
}

.accordion-link:hover {
  color: rgba(255, 255, 255, 0.8);
}

.accordion-link i {
  color: #baff39;
  padding: 0.5rem;
}

.accordion-link .minus {
  display: none;
}

.answer {
  overflow: scroll;
  max-height: 0;
  position: relative;
  transition: max-height 650ms;
  background-color: #24262a;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.4);
}

.answer::before {
  content: "";
  position: absolute;
  width: 0.6rem;
  height: 90%;
  background-color: #baff39;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
}

.answer p {
  font-size: 1.4rem;
  color: white;
  padding: 2rem;
}

.faqHeader {
  margin-top: 18rem;
  margin-bottom: 40px;
  text-align: center;
  font-family: basic-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
  font-size: 70px;
}

.accordion-item:target .answer {
  max-height: 20rem;
}

.accordion-item:target .accordion-link .plus {
  display: none;
}

.accordion-item:target .accordion-link .minus {
  display: block;
}

.termsLink {
  font-size: 1rem;
  font-weight: 400;
  text-align: center;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
  color: #2bc6e5;
}

.termsContainer {
  margin: 5% 15%;
  font-family: basic-sans, sans-serif;
  color: white;
  font-size: 1rem;
}

.termsHeader {
  font-size: 2rem;
  letter-spacing: 1rem;
  padding-top: 2rem;
  padding-bottom: 2rem;
  font-weight: 700;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.termsContent {
  line-height: 2rem;
}

.fab.fa-instagram.fa-lg:hover {
  transform: scale(1.2);
}

.fab.fa-youtube-square.fa-lg:hover {
  transform: scale(1.2);
}

.fab.fa-twitter-square.fa-lg:hover {
  transform: scale(1.2);
}

.weAreTitle {
  font-size: 3rem;
}

.parallax-container {
  position: relative;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.parallax-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.parallax-content {
  position: relative;
  z-index: 1;
}

.parallax-content-inner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.parallax-logo {
  max-width: 55%;
  max-height: 55%;
  width: auto;
  height: auto;
}

.parallax-content-text {
  position: relative;
  z-index: 1;
}

.welcome-text {
  transform: translateX(0);
  transition: transform 0.3s ease;
  color: white;
  font-family: basic-sans, sans-serif;
  font-weight: 900;
  font-style: normal;
  text-align: center;
  font-size: 7rem;
  text-shadow: 0 0 5px black;
}

@media screen and (max-width: 1024px) {
  /* body {
   
    width: 100%;
    height: 100%;
  } */

  .moviePosters {
    height: 32vh;
    width: 26.5vw;
  }

  .renderContainer {
    text-align: center;
    border-bottom: 10px solid rgb(160, 144, 144);
    border-bottom-style: inset;
    -webkit-box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
    background-image: radial-gradient(#212121 20%, beige 20%);
    background-position: 0 0;
    background-size: 27px 27px;
    padding-top: 10px;
  }

  .movieContainer {
    width: 30%;
    background-size: 0 0;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  .grid-item {
    font-size: 30px;
    text-align: center;
    min-width: minmax(0, 1fr);
    background-size: cover;
  }

  .title {
    font-size: 9rem;
    margin-top: 90%;
  }

  .grayBackground {
    width: 100%;
    height: 300px;
  }

  .grayBackgroundInner {
    height: 140px;
  }

  .chevron.left:before {
    left: -2em;
    transform: rotate(-135deg);
    margin-left: 5.2rem;
  }

  .chevron.right:before {
    left: 2em;
    transform: rotate(45deg);
    margin-right: 5.2rem;
  }

  .dolph {
    margin-bottom: 2rem;
  }

  .drDolph {
    margin-top: 0px;
  }

  .writing-item {
    flex-direction: column;
  }
  .writing-img {
    margin: 0 0 2rem 0;
  }
  .writing-description {
    margin: 0;
    order: 2;
  }

  .modal-synopsis {
    min-height: 12%;
    max-height: 14%;
    padding-top: 1rem;
  }
  .supportTitle {
    font-size: 60px;
  }

  .weAreTitle {
    font-size: 60px;
  }
  .contactText {
    font-size: 70px;
    text-align: center;
    padding: 10px;
  }
  .contactLogoContainer img {
    max-width: 30%;
    max-height: 30%;
  }

  .contactPageEmail {
    top: 75%;
  }

  .searchAndFilters {
    display: block;
    width: 24%;
    margin-left: 1rem;
  }
  .scrollTop {
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 1;
    height: 50px;
    width: 50px;
  }
}

@media screen and (max-width: 600px) {
  /* body {
    width: 100%;
    height: 100%;
  } */

  .welcome-text {
    font-size: 5rem;
  }

  #film {
    font-size: 1.9em;
    margin-left: 0%;
  }

  .grayBackground {
    width: 100%;
  }

  .randomizerText {
    font-size: 30px;
    margin-bottom: 1rem;
  }

  .randomizerSubtext {
    font-size: 20px;
  }

  .randomContainer {
    display: block;
  }

  .randomButtonDiv {
  }

  .noActorPicture {
    max-height: 22rem;
  }

  .noActorText {
    font-size: 1.5rem;
  }

  #contactNav {
    margin-right: 10px;
  }

  .articlesBackground {
  }

  .movieGameTextContainer {
    width: 90%;
  }

  .employeePickContainer {
    width: 90%;
  }

  .genreSign.employee {
    width: 86%;
    margin-top: 5%;
  }

  .employeePickWeek {
    width: 75%;
    margin-top: 5%;
  }

  .movieGameAvatar {
    min-width: 80px;
    min-height: 80px;
  }

  .grayBackgroundInner {
    width: 90%;
    font-size: 1.43rem;
  }

  .dolph {
    justify-content: center;
    margin-bottom: 1rem;
  }

  .relatedX {
    height: 10px;
  }

  .supportTitle {
    font-size: 50px;
  }

  .weAreTitle {
    font-size: 50px;
  }

  .games-title {
    font-size: 7rem;
  }

  .writing-item {
    flex-direction: column;
  }
  .writing-img {
    margin: 0 0 2rem 0;
  }
  .writing-description {
    margin: 0;
    order: 2;
  }

  .contactText {
    font-size: 50px;
    text-align: center;
    padding: 10px;
    margin-top: 15%;
  }

  .contactLogoContainer img {
    max-width: 70%;
    max-height: 30%;
  }

  .scrollTop {
    animation: fadeIn 0.3s;
    transition: opacity 0.4s;
    opacity: 1;
    height: 40px;
    width: 40px;
  }

  .contactPageEmail {
    text-align: center;
  }

  .drDolph {
    margin-top: 2rem;
    font-size: 14px;
  }

  .filterContainer {
    margin-left: 0.9rem;
    width: 86%;
  }

  .actorSearchAndGenre {
    display: block;
    width: 90%;
  }

  .genreSign.actor {
    margin: 0 auto;
    width: 83%;
    line-height: normal;
  }

  .FAQ {
    margin-top: 180px;
    width: 85%;
    margin-bottom: 150px;
  }

  .genreSign.rated {
    width: 83%;
    margin: 0 auto;
    font-size: 1.5rem;
    line-height: normal;
    font-size: 40px;
  }

  .genreSearchSign {
    margin-left: 15px !important;
    width: 92%;
  }

  #demo-simple-select-standard {
    width: 300px;
  }

  .searchAndGenre {
    width: 90%;
    flex-direction: column;
    align-items: flex-start;
  }

  .genreSign {
    width: 80%;
    font-size: 2.5em;
  }

  .genreSignContainer {
    width: 90%;
    flex-direction: column;
    height: 170px;
  }

  .numberContainer {
    margin-left: 0px;
    width: 95%;
  }

  .reviewsReimagined {
    font-size: 55px;
  }

  .title {
    font-size: 5rem;
    margin-top: 132%;
  }

  #slobs {
    font-size: 1.9em;
  }
  .navItems {
    font-size: 1.2em;
    display: none;
  }
  .moviePosters {
    height: 170px;
    width: 100px;
  }

  .movieContainer {
    width: 30%;
    background-size: 0 0;
  }

  .renderContainer {
    text-align: center;
    border-bottom: 10px solid rgb(160, 144, 144);
    border-bottom-style: inset;
    -webkit-box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 10px 0px rgba(0, 0, 0, 0.2);
    background-image: radial-gradient(#212121 20%, beige 20%);
    background-position: 0 0;
    background-size: 27px 27px;
    padding-top: 10px;
    width: 90%;
  }

  .grid-container {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    height: auto;
  }
  .grid-item {
    font-size: 30px;
    text-align: center;
    min-width: minmax(0, 1fr);
    background-size: cover;
  }

  .article-container {
    width: 90%;
  }

  .modal {
    width: 88%;
    height: 38rem;
    /* position: fixed;
    top: 0; */
  }

  .modal-synopsis {
    font-size: 0.9em;
    max-height: 11.25%;
    padding-top: 1rem;
    overflow: scroll;
  }

  .picture1 {
    max-width: 13rem;
    height: 6.9rem;
    max-height: 6.91rem;
  }

  .picture2 {
    height: 6.9rem;
    max-height: 6.91rem;
    max-width: 8.2rem;
  }

  .picture3 {
  }

  .img-img {
    width: 100%;
    display: block;
    border: 2px solid white;
    height: 15rem;
    max-height: 16rem;
  }

  .img-img-reviewed {
    min-width: 5.8rem;
    max-width: 5.8rem;
    min-height: 5.8rem;
    max-height: 5.8rem;
    margin-top: 1.5rem;
  }

  .img-reviewed {
    height: 5rem;
  }

  .topPicsContainer {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }

  .bottomContainer {
    width: 100%;
    display: flex;
    font-size: 0.8em;
    text-align: center;
    margin-left: -3%;
  }

  .pairsWithTextDiv {
    font-size: 0.9em;
    max-height: 1.5rem;
    overflow-y: scroll;
  }
  .tagline {
    color: gold;
    font-size: 1em;
  }

  .tagline-writer {
    text-align: center;
    color: gold;
    font-size: 0.7em;
  }
  .bonusFeatures {
    width: 100%;
    border: 2px solid #05cbe9;
    padding: 1%;
    background-color: gray;
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    display: flex;
    margin-left: -15px;
    border-left: none;
    margin-top: 20px;
    font-size: 1.1em;
    min-height: 140px;
    max-height: 160px;
  }
  .bonusFeatureBullets {
    border-top-right-radius: 100px;
    border-bottom-right-radius: 100px;
    font-weight: 500;
    color: black;
    margin-left: 6%;
    width: 100%;
    font-size: 0.75em;
  }

  .movieTitleText {
    height: 30px;
    font-size: 12px;
  }

  .bonusFeatureBulletContainer {
    font-size: 0.8em;
    letter-spacing: 0px;
  }
  .modal-filmInfo {
    display: flex;
    margin-top: 5px;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
    padding: 5px;
    width: 99%;
  }

  .cast {
    text-align: center;
    font-size: 0.6em;
    font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, sans-serif;
    z-index: 1;
    position: relative;
  }

  .castText {
    max-height: 1.6rem;
    overflow: scroll;
    text-overflow: ellipsis;
  }

  .relatedContainer {
    width: 91vw;
    min-height: 24%;
    max-height: 27%;
    background-color: #ffd700;
    position: absolute;
    animation: fadein 1s;
    border: 2px solid blue;
    margin-left: -1%;
  }

  .article-image-vertical {
    min-height: 28rem;
    max-height: 28rem;
    max-width: 19rem;
  }

  .relatedContainer img {
    min-height: 7.5rem;
    max-height: 9.5rem;
  }

  .recipeContainer {
    width: 104%;
    max-width: 110%;
    margin-left: -1%;
    z-index: 10;
    position: absolute;
    bottom: -35%;
    min-height: 5%;
  }

  .recipeIngredients {
    font-size: 1.2em;
    text-align: center;
    font-weight: bold;
    color: blue;
    font-family: "Codystar", cursive;
    font-weight: 700;
    text-shadow: 1px 1px 1px grey;
    padding: 1%;
  }

  .relatedTitles {
    color: blue;
    font-size: 1.3em;
  }

  .searchAndFilters {
    display: block;
    width: 101.5%;
    margin-left: 0.8rem;
  }

  .dvdPicture {
    width: 80%;
  }

  #outlined-basic {
    background-color: white;
  }
  .notRatedTitle {
    font-family: basic-sans, sans-serif;
    font-size: 30px;
    font-weight: 600;
    text-align: center;
    max-height: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .notRatedPoster {
    height: 300px;
    border: 2px solid #baff39;
    margin: 0 auto;
  }

  .notRatedPoster:hover {
    cursor: pointer;
  }

  .alsoWrittenByContainer {
    margin-bottom: 5rem;
  }
  .modal-notRated {
    width: 18rem;
    max-width: 750px;
    height: 22rem;
    background: linear-gradient(70deg, #000511 0%, #213763 100%);
    color: #fff;
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-top: 20%;
    padding: 1em;
    border: 2.5px solid #baff39 !important;
    border-radius: 5px;
  }

  .modal-filmInfo-notRated {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    align-items: center;
    font-weight: bold;
    font-size: 17px;
    padding: 5px;
  }

  .filters {
    margin-right: 0px;
  }

  .notRatedPosterContainer {
    display: flex;
    position: relative;
  }

  .filmslobsHomepageContainer {
    display: flex;
  }

  .infoContainer {
  }

  .movieName {
    font-size: 1.25rem;
  }

  .randomCard {
    background-image: url(./VHS-vert.png);
    background-size: cover;
    background-repeat: no-repeat;
    max-width: 70%;
    height: 510px;
  }

  .randomizerLimit {
    font-size: 1.1rem;
  }
  .imageContainer {
    float: none;
  }

  .img {
    position: relative;
    width: 10rem;
    max-width: 150px;
    margin: 0 auto;
  }

  .img-title {
    font-size: 1.8em;
  }

  .background {
    transform: translateZ(-5px) scale(1.5);
    margin-left: 0rem;
    margin-top: -8rem;
  }

  .infoContainer {
    max-height: 235px;
    width: 87%;
    max-width: 250px;
    margin-left: 0%;
  }

  .input {
    min-width: 90%;
    max-width: 91%;
  }

  .movieGameTitle {
    font-size: 60px;
  }

  .ratingsContainer {
    font-size: 0.8rem;
  }

  .synopsis {
    font-size: 0.6em;
    margin-bottom: 0.5rem;
  }

  .pairsWithRandom {
    line-height: 10px;
  }

  .pairsWithRandomText {
    font-size: 0.6em;
  }

  .img-overlay-reviewed {
    max-height: 7.5rem;
  }

  .burgerBar {
    margin-bottom: 0.3rem;
  }

  .hamburgerMenu {
  }

  .burgerBars {
    display: block;
    position: fixed;
    left: 90%;
    top: 2.5%;
    z-index: 10;
  }

  .burgerBars {
    cursor: pointer;
  }

  .chevron.left:before {
    margin-left: 0.3rem;
  }
  .chevron.right:before {
    margin-right: 0.3rem;
  }
  .chevron.left.desktop:before {
    display: none;
  }

  .chevron.right.desktop:before {
    display: none;
  }

  .mobileChevronsArticlesPage {
    display: block;
  }

  .expandedBurgerContainer {
    position: fixed;
    width: 100%;
    margin-top: 1.3rem;
    height: 100vh;
    background-color: black;
    top: 4.4%;
    z-index: 20;
  }

  .nameAndBlurbContainer {
    margin-left: 1rem;
    width: 100%;
  }

  .article-container-header p {
    font-size: 2.2rem;
  }

  .navLinks {
  }

  .img-description {
    font-size: 1em;
  }

  .navLink {
    font-size: 2.5rem;
    text-align: center;
    margin-top: 5rem;
    margin-bottom: 3rem;
    font-family: basic-sans, sans-serif;
  }

  .movieNameDrinksText {
    max-width: 13rem;
    overflow-y: scroll;
    max-height: 5rem;
    white-space: nowrap;
  }
  .navLink:hover {
    text-decoration: none;
    color: #baff39;
  }

  .close {
    color: transparent;
    text-shadow: 0 0 0 #baff39;
    position: fixed;
    left: 90%;
    top: 1.8%;
    z-index: 10;
    font-size: 20px;
  }
  .close:hover {
    cursor: pointer;
  }
  .writing-item:hover {
    background-color: #2e2e2e;
  }
  #content {
    margin: 50px;
  }

  #content2 {
    margin: 50px;
    margin-top: 5rem;
  }

  .mobileSearch:hover {
    color: #baff39;
  }

  .videoBackground {
    background-size: auto;
  }

  a {
    text-decoration: none;
  }

  .expandable {
    color: #000;
    transition: all 0.5s ease-in-out;
    height: 0rem;
    overflow: hidden;
  }

  .expandable:target {
    height: 13rem;
  }
  .mobileDropdownItem {
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
    text-decoration: none;
    color: #baff39;
    text-align: center;
  }

  .bonusFeatures {
    border: none;
    margin-top: 10px;
  }

  .articleContentContainer {
    display: flex;
    flex-direction: column;
  }
  .currentRoute {
    margin-top: 20%;
    margin-left: -10px;
  }

  .social-button {
    min-width: 2.7rem;
  }
  .relatedSidebar {
    width: 90%;
  }

  .image-and-caption {
    margin-bottom: 1.5rem;
  }
  .top-social-icons {
    margin-left: 37%;
  }
  .seeAlso {
    font-size: 1.6rem;
  }
  .writing-item {
    padding-left: 0rem;
  }

  .faqHeader {
    font-size: 2.5rem;
    margin-top: 5rem;
  }

  .bottomContainerContainer {
    margin-top: 10px;
  }

  .aboutWriterContainer {
    display: block;
    width: 100%;
    padding: 0%;
    margin-top: 4rem;
  }

  .writerName {
    font-size: 2.2rem;
  }

  .writerThumbnail {
    width: 10rem;
    height: 10rem;
    margin: 0 auto;
    margin-top: 6rem;
    margin-bottom: 1.5rem;
  }

  .seeAlsoHeader {
    width: 100%;
    padding-bottom: 0px;
  }

  .otherArticles {
    margin-top: 1rem;
    width: 100%;
    padding: 5px;
  }

  .modalCloseX {
    right: 3%;
    top: 1%;
  }

  .recommendedDrink {
    text-decoration: underline;
  }

  .form-container {
    max-width: 90%;
    margin-top: 0rem;
  }

  .thumbnailTitleContainer {
    display: block;
  }

  .otherArticlesThumbnail {
    margin-top: 1rem;
    min-width: 20.2rem;
    max-width: 20.22rem;
  }

  .otherArticleTitle {
    margin-left: 0px;
  }

  .otherTitleSynopsisContainer {
    margin-left: 0px;
  }

  .otherArticleSynopsis {
    margin-left: 0px;
  }

  .readMore {
    margin-left: 0px;
  }

  .randomBullets {
    font-size: 0.6em;
  }

  .scrollableContainer {
    max-height: 235px;
    overflow-y: auto;
  }

  .randomSpecialFeatures {
    font-size: 0.6em;
  }

  .otherArticleSynopsis {
    margin-top: 10px;
  }

  .parallax-logo {
    max-width: 100%;
  }

  .img-img-reviewed-random {
    min-width: 10rem;
    max-width: 10.1rem;
    min-height: 15rem;
    max-height: 15.1;
    border: 2px solid #05cbe9;
    margin-top: 5px;
    margin-bottom: 5px;
  }
  .img-overlay-reviewed-random {
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    min-height: 15rem;
    max-height: 15.1rem;
    margin-top: 5px;
    min-width: 10rem;
    max-width: 10.1rem;
    background: rgba(0, 0, 0, 0.6);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.25s;
    backdrop-filter: blur(5px);
    border: 2px solid #05cbe9;
    text-align: center;
  }
  .img-title-reviewed-random {
    font-size: 2.8em;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
